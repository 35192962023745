import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import BooksSlider from "../../../components/BooksSlider";
import { getBooksAction } from "../../../store/Books/actions";
import { ROUTE_PATHS } from "../../../utils/PathsNames";

const FeaturedBooks = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const {
    books: { FeaturedBooksTitle }
  } = messages;

  const featuredList = useSelector(
    (state) => state.books.featured.list
  );
  const { pathname } = useLocation();
  const isEditor = pathname.includes("editor");
  const isAuthor = pathname.includes("author");
  const isProgram = pathname.includes("program");
  useEffect(() => {
    const params = {
      page: 1,
      items: 5,
      is_featured: true,
      isAuthor,
      isEditor,
      isProgram
    };
    function getBooks() {
      dispatch(getBooksAction(params));
    }
    getBooks();
  }, []);

  return (
    <>
      {!(isAuthor || isEditor) && featuredList?.length !== 0 && (
        <BooksSlider
          title={FeaturedBooksTitle}
          slides={featuredList}
          color="secondary"
          fullLibraryLink={`${ROUTE_PATHS["books"]}/featured`}
        />
      )}
    </>
  );
};

export default FeaturedBooks;
