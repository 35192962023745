import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
// import { Col, Container, Row } from "react-bootstrap";

import AuthLayout from "../../../components/AuthLayout";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  getBookTestsRequest,
  getSingleBookSupplementariesAction,
  getBookQuizzes
} from "../../../store/Books/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faImage,
  // faPlayCircle,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import {
  axiosInstance,
  customer_portal
} from "../../../network/apis";
// import videoiconBW from "../../../assets/svgs/VideoiconBW.svg";
import quiziconBW from "../../../assets/svgs/QuiziconBW.svg";
import testiconBW from "../../../assets/svgs/TesticonBW.svg";
import completedIcon from "../../../assets/svgs/CompletedIcon.svg";
import styles from "../AllBookSupplementaries/bookSupplementaries.module.scss";
import BackButton from "../../../components/BackButton";
import Collapse from "react-bootstrap/Collapse";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import Button from "../../../components/Button";
import { getFinalTestRequest } from "../../../store/FinalTest/actions";
import { showHideSnackbar } from "../../../store/Snackbar/actions";

const AllBookSupplementaries = () => {
  const { messages } = useIntl();
  const {
    books: { lectures, tests },
    test: {
      pretestnotsolved,
      pretest,
      posttest,
      finaltest,
      FinalTestNotFound
    }
  } = messages;
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const finalTest = useSelector(
    (state) => state.FinalTestReducer.finalTest
  );
  console.log("FinalTest at ListTests :", finalTest);
  useEffect(() => {
    dispatch(getFinalTestRequest({ book_id: id }));
  }, []);
  const {
    books: { singleBookTests, singleBookQuizzes }
  } = useSelector((state) => state);
  const renderRegisterForm = () => {
    useEffect(() => {
      dispatch(getSingleBookSupplementariesAction({ book_id: id }));
      dispatch(getBookTestsRequest({ book_id: id }));
      dispatch(getBookQuizzes({ book_id: id, page: -1 }));
    }, [id]);

    const [open, setOpen] = useState([]);
    const handleToggle = (index) => {
      setOpen((prevOpen) => {
        const newOpen = [...prevOpen];
        if (newOpen[index] === undefined) newOpen[index] = true;
        else newOpen[index] = !newOpen[index];
        return newOpen;
      });
    };

    const sortedTests = singleBookTests.sort(
      (a, b) => a.pre_page - b.pre_page
    );
    const chapterQuizzes = sortedTests.map((test) => {
      return singleBookQuizzes.filter(
        (quiz) =>
          quiz.book_page >= test.pre_page &&
          quiz.book_page <= test.post_page
      );
    });

    const [testResults, setTestsResults] = useState([]);
    const [quizResults, setQuizResults] = useState([]);
    useEffect(() => {
      let isMounted = true;

      const fetchURL = (url) =>
        axiosInstance.get(`${customer_portal}/${url}`);

      const fetchTests = async () => {
        try {
          const promiseArray = sortedTests
            .map((test) => `tests/${test.id}`)
            .map(fetchURL);

          const promiseResult = await Promise.all(promiseArray);

          if (isMounted) {
            setTestsResults(
              promiseResult.map((test) => {
                const thisTest = test.data.test;
                return {
                  ...thisTest,
                  isPreTestSolved: thisTest?.test_questions?.every(
                    (question) => !!question[`pre_test_user_answer`]
                  ),
                  isPostTestSolved: thisTest?.test_questions?.every(
                    (question) => !!question[`post_test_user_answer`]
                  )
                };
              })
            );
          }
        } catch (error) {
          // Handle errors here
          console.error(error);
        }
      };

      const fetchQuizzes = async () => {
        try {
          const promiseArrayQuiz = chapterQuizzes.map((test) =>
            test
              .map(
                (quiz) =>
                  `/quizzes/${quiz.quiz_id}?book_id=${id}&book_page=${quiz.book_page}`
              )
              .map(fetchURL)
          );

          const nestedPromiseResult = await Promise.all(
            promiseArrayQuiz.map((promiseArray) =>
              Promise.all(promiseArray)
            )
          );

          if (isMounted) {
            setQuizResults(
              nestedPromiseResult.map((test) =>
                test.map((quiz) => {
                  const thisQuiz = quiz.data.quiz;
                  return {
                    ...thisQuiz,
                    isQuizSolved: thisQuiz?.questions?.every(
                      (question) => !!question[`user_answer`]
                    )
                  };
                })
              )
            );
          }
        } catch (error) {
          // Handle errors here
          console.error(error);
        }
      };

      const fetchData = async () => {
        fetchTests();
        fetchQuizzes();
      };

      fetchData();

      return () => {
        isMounted = false;
      };
    }, [sortedTests, singleBookQuizzes]);
    const goToFinalTestOrScoreView = () => {
      if (finalTest?.user_answers?.answers?.length > 0) {
        history.push(`${ROUTE_PATHS.FinalTestScore}/${id}`);
      } else if (finalTest?.details?.questions?.length > 0) {
        history.push(`${ROUTE_PATHS.FinalTest}/Take/${id}`);
      } else {
        dispatch(
          showHideSnackbar({
            isOpen: true,
            type: "error",
            message: FinalTestNotFound
          })
        );
      }
    };
    const handleVidButtonClick = () => {
      const route = `${ROUTE_PATHS["bookAllVideos"]}/${id}`;
      history.push(route);
    };
    const goToBookViewer = () => {
      const route = `${ROUTE_PATHS["bookViewer"]}/${id}`;
      history.push(route);
    };

    const RenderTooltip = ({ children }) => (
      <OverlayTrigger overlay={<Tooltip>{pretestnotsolved}</Tooltip>}>
        {children}
      </OverlayTrigger>
    );

    const renderTestSpan = (
      index,
      sortedTests,
      test,
      testResults
    ) => (
      <span>
        <Link
          className={`${styles.supplementary_title} ${
            !testResults[index]?.isPreTestSolved
              ? styles.testDisabled
              : ""
          }`}
          to={`${ROUTE_PATHS["testdisplay"]}/${test.id}/1/${id}`}
        >
          {testResults[index]?.isPostTestSolved ? (
            <img
              src={completedIcon}
              className={`mx-3 ${styles.icon}`}
            />
          ) : (
            <img src={testiconBW} className={`mx-3 ${styles.icon}`} />
          )}

          {`${sortedTests[index].title} ${
            sortedTests[index].title
              .toLowerCase()
              .includes("final test")
              ? finaltest
              : posttest
          }`}
        </Link>
      </span>
    );

    RenderTooltip.propTypes = {
      children: PropTypes.any
    };

    return (
      <>
        <div className={styles.header}>
          <div className="my-auto d-flex">
            <BackButton
              className={`my-auto me-4 ${styles.backButton}`}
              icon={"b"}
              otherWise={goToBookViewer}
            />
            <span className={styles.headerText}>Course Tests</span>
          </div>
          <div className={styles.buttonsContainer}>
            <button
              className={`${styles.button} ${styles.buttonLeft}`}
              onClick={handleVidButtonClick}
            >
              {lectures}
            </button>
            <button
              className={`${styles.button} ${styles.buttonRight} ${styles.buttonSelected}`}
            >
              {tests}
            </button>
          </div>
        </div>
        {sortedTests?.map((test, index) => (
          <div className={styles.chapterContainer} key={test.id}>
            <div
              className={`${styles.pagesContainer}
                ${
                  testResults[index]?.isPostTestSolved
                    ? styles.pagesContainerComplete
                    : testResults[index]?.isPreTestSolved
                    ? styles.pagesContainerInProgress
                    : ""
                }`}
            >
              {test.pre_page} - {test.post_page}
            </div>
            <div
              className={`${styles.outerSupplementariesContainer} ${
                index == 0 ? styles.firstOuter : ""
              } ${
                index == sortedTests.length - 1
                  ? styles.lastOuter
                  : ""
              } ${
                testResults[index]?.isPostTestSolved
                  ? styles.outerSupplementariesContainerComplete
                  : ""
              }`}
            >
              <div className={styles.supplementariesContainer}>
                <div className={styles.chapterTitleContainer}>
                  <span className={styles.chapterTitle}>{`Chapter ${
                    index + 1
                  } : ${test.title}`}</span>
                </div>
                <div className={styles.supplementariesNameContainer}>
                  <img src={quiziconBW}></img>
                  <span className={styles.supplementaryName}>
                    {chapterQuizzes[index].length}
                    {chapterQuizzes[index].length >= 1
                      ? " Quizzes"
                      : " Quiz"}
                  </span>
                  <img src={testiconBW} width="25" height="24"></img>
                  <span className={styles.supplementaryName}>
                    Test
                  </span>
                  <div
                    className={`${styles.dropDownContainer} ${
                      styles.transitionicon
                    } ${open[index] ? styles.rotateup : ""}`}
                    onClick={() => {
                      handleToggle(index);
                    }}
                  >
                    <FontAwesomeIcon icon={faAngleDown} />
                  </div>
                </div>
              </div>
              <Collapse in={open[index]}>
                <div
                  className={`${styles.thinScrollBar} ${styles.innerListContainer}`}
                >
                  {
                    <div
                      className={`d-flex justify-content-between ${styles.innerListItem}`}
                    >
                      <span>
                        <Link
                          className={`${styles.supplementary_title}`}
                          to={`${ROUTE_PATHS["testdisplay"]}/${test.id}/0/${id}`}
                        >
                          {testResults[index]?.isPreTestSolved ? (
                            <img
                              src={completedIcon}
                              className={`mx-3 ${styles.icon}`}
                            />
                          ) : (
                            <img
                              src={testiconBW}
                              className={`mx-3 ${styles.icon}`}
                            />
                          )}

                          {`${sortedTests[index].title} ${pretest}`}
                        </Link>
                      </span>
                    </div>
                  }
                  {chapterQuizzes[index]?.length ||
                  sortedTests[index]?.length ? (
                    <>
                      {chapterQuizzes[index].map((test, i) => (
                        <div
                          key={i}
                          className={`d-flex justify-content-between ${styles.innerListItem}`}
                        >
                          <span>
                            <Link
                              className={`${styles.supplementary_title}`}
                              to={`${ROUTE_PATHS["quizdisplay"]}/${test.quiz_id}/${id}/${test.book_page}`}
                            >
                              {quizResults[index]?.[i]
                                ?.isQuizSolved ? (
                                <img
                                  src={completedIcon}
                                  className={`mx-3 ${styles.icon}`}
                                />
                              ) : (
                                <img
                                  src={quiziconBW}
                                  className={`mx-3 ${styles.icon}`}
                                />
                              )}

                              {test.quiz_title}
                            </Link>
                          </span>
                        </div>
                      ))}
                      {
                        <div
                          className={`d-flex justify-content-between ${styles.innerListItem}`}
                        >
                          {!testResults[index]?.isPreTestSolved ? (
                            <RenderTooltip>
                              {renderTestSpan(
                                index,
                                sortedTests,
                                test,
                                testResults
                              )}
                            </RenderTooltip>
                          ) : (
                            renderTestSpan(
                              index,
                              sortedTests,
                              test,
                              testResults
                            )
                          )}
                        </div>
                      }
                    </>
                  ) : (
                    <p className="fsize-20 text-center">
                      No Book Supplementaries are added yet
                    </p>
                  )}
                </div>
              </Collapse>
            </div>
          </div>
        ))}
        <div className="d-flex justify-content-center mt-2">
          <Button
            onClick={goToFinalTestOrScoreView}
            type="button"
            text="Final Test"
          />
        </div>
      </>
    );
  };
  return <AuthLayout body={renderRegisterForm()} />;
};

export default AllBookSupplementaries;
