import React, { useEffect, useState } from "react";
import "./TableStudents.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SingleRow from "../SingleRow";
import Box from "@mui/material/Box";
import SearchIcon from "../../../assets/svgs/search-icon";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getStudentsListRequest } from "../../../store/StudentsList/action";
import { useIntl } from "react-intl";

function TableStudents() {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("");
  const [searchName, setSearchName] = useState("");

  const arr = useSelector((state) => state.studentslist.resList);
  const [editArray, setEditArray] = useState(arr);

  useEffect(() => {
    dispatch(getStudentsListRequest());
  }, [dispatch]);
  useEffect(() => {
    let editArray = arr.filter((row) => {
      const completeName =
        row.user.first_name + " " + row.user.second_name;
      return completeName
        .toLowerCase()
        .includes(searchName.toLowerCase());
    });

    setEditArray(editArray);
  }, [searchName, arr]);

  console.log("arr", arr);
  const handleSearchChange = (e) => {
    setSearchName(e.target.value);
  };
  const { messages } = useIntl();
  const {
    studentsList: {
      TableHeader: { name, lastCourse, lastChapter }
    }
  } = messages;
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          mb: "100px"
        }}
      >
        <Box
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1%" // Add margin-bottom for spacing between search bar and table
          }}
        >
          <Box sx={{ flexGrow: 1 }}></Box>{" "}
          {/* Empty Box to push the search bar to the right */}
          <Box>
            <form>
              <div className="search-container">
                <input
                  className="search-field"
                  type="text"
                  placeholder="search"
                  value={searchName}
                  onChange={(e) => handleSearchChange(e)}
                />
                <SearchIcon />
              </div>
            </form>
          </Box>
        </Box>

        <TableContainer
          sx={{
            width: "80%",
            boxShadow: "none",
            border: "none",
            borderRadius: "5px"
          }}
        >
          <Table
            sx={{
              borderCollapse: "collapse",
              border: "none"
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ color: "#9F9C9C", borderBottom: "none" }}
                >
                  {name}
                </TableCell>
                <TableCell
                  sx={{ color: "#9F9C9C", borderBottom: "none" }}
                >
                  {lastCourse}
                </TableCell>
                <TableCell
                  sx={{ color: "#9F9C9C", borderBottom: "none" }}
                  align="right"
                >
                  {lastChapter}
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "none" }}
                  align="right"
                >
                  <FormControl sx={{ m: 1, minWidth: 100 }}>
                    <Select
                      value={selectedValue}
                      onChange={(e) =>
                        setSelectedValue(e.target.value)
                      }
                      sx={{ height: "2.4rem", color: "#9F9C9C" }}
                      displayEmpty
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                    >
                      <MenuItem sx={{ color: "#9F9C9C" }} value="">
                        sort
                      </MenuItem>
                      <MenuItem
                        sx={{ color: "#9F9C9C" }}
                        value="Name"
                      >
                        Name
                      </MenuItem>
                      <MenuItem
                        sx={{ color: "#9F9C9C" }}
                        value="Date"
                      >
                        Date
                      </MenuItem>
                      <MenuItem
                        sx={{ color: "#9F9C9C" }}
                        value="progress"
                      >
                        progress
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {editArray.map((row, index) => (
                <SingleRow key={index} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default TableStudents;
