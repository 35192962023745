import React, { useEffect } from "react";
import BackButton from "../../../components/BackButton";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import { useParams, useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CircularProgressbar } from "react-circular-progressbar";
import Button from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { getFinalTestRequest } from "../../../store/FinalTest/actions";

function ViewScoreFinalTest() {
  const { book_id } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const {
    finalTest: {
      finalTest,
      reTakeFinalTest,
      viewFinalTest,
      success,
      failed,
      bestScoreAchieved
    },
    buttons: { ok }
  } = messages;

  const finalTestAnswers = useSelector(
    (state) => state.FinalTestReducer.finalTest
  );

  useEffect(() => {
    dispatch(getFinalTestRequest({ book_id }));
  }, []);

  const handleBackButtonClick = () => {
    const route = `${ROUTE_PATHS["bookViewer"]}/${book_id}`;
    history.push(route);
  };
  const handleBackToRetakeFinalTest = () => {
    const route = `${ROUTE_PATHS.FinalTest}/Take/${book_id}`;
    history.push(route);
  };
  const handleBackToViewFinalTest = () => {
    const route = `${ROUTE_PATHS.FinalTest}/View/${book_id}`;
    history.push(route);
  };
  const handleTestButtonClick = () => {
    const route = `${ROUTE_PATHS["bookAllTests"]}/${book_id}`;
    history.push(route);
  };
  return (
    <>
      <div className={`mx-auto w-80 relative`}>
        <div className="my-auto d-flex">
          <BackButton
            className="my-auto me-4 backButton"
            icon={"b"}
            otherWise={handleTestButtonClick}
          />
          <span className="headerText">Course Tests</span>
        </div>

        <Typography
          id="modal-modal-title"
          variant="h5"
          className="text-center text-primary mb-4"
        >
          {finalTestAnswers.book_title} {finalTest}
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="h6"
          className="text-center text-primary mb-4"
        >
          {bestScoreAchieved}
        </Typography>
        {finalTestAnswers?.user_answers?.score > 50 ? (
          <p className="success text-center my-4">{success}</p>
        ) : (
          <p className="error text-center my-4">{failed}</p>
        )}
        <div className="precentage">
          <CircularProgressbar
            value={Math.round(finalTestAnswers?.user_answers?.score)}
            text={`${Math.round(
              finalTestAnswers?.user_answers?.score
            )}%`}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center flex-column align-items-center">
        <div className="my-3">
          <Button
            text={reTakeFinalTest}
            className="continue_btn"
            onClick={() => {
              handleBackToRetakeFinalTest();
            }}
          />
        </div>
        <div className=" my-3">
          <Button
            text={viewFinalTest}
            className="continue_btn"
            onClick={() => {
              handleBackToViewFinalTest();
            }}
          />
        </div>

        <div className="my-3">
          <Button
            text={ok}
            onClick={() => {
              handleBackButtonClick();
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ViewScoreFinalTest;
