import { put, call } from "redux-saga/effects";
import * as apis from "../../network/apis/Topics";
import * as commentsApis from "../../network/apis/comments";
import {
  deleteSingleTopicResponce,
  getAllTopicsResponce,
  getCurrentProgramResponce,
  getSingleTopicResponce,
  setTopicResponse
} from "./actions";
import { showHideSnackbar } from "../Snackbar/actions";
import messages from "../../assets/locale/messages";

export function* setNewTopicSaga({ payload }) {
  try {
    const { programId, topic } = payload;
    const response = yield call(
      apis.setNewTopicProgram,
      programId,
      topic
    );

    yield put(setTopicResponse());
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].topic.addMessage
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getCurrentProgramSaga({ payload }) {
  try {
    const response = yield call(apis.currentProgram, payload);

    yield put(
      getCurrentProgramResponce({
        currentProgram: response.data.program
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getAllToicsSaga({ payload }) {
  try {
    const { programId } = payload;

    const response = yield call(apis.getTopics, programId);

    yield put(
      getAllTopicsResponce({
        TopicsList: response.data.topics
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getCustomerToicsSaga({ payload }) {
  try {
    const { programId } = payload;

    const response = yield call(apis.getCusromerTopics, programId);

    yield put(
      getAllTopicsResponce({
        TopicsList: response.data.topics
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getSingleToicSaga({ payload }) {
  try {
    const { programId, topicId } = payload;

    const response = yield call(
      apis.getSingleTopic,
      programId,
      topicId
    );

    yield put(
      getSingleTopicResponce({
        Topic: response.data.topic
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* getSingleToicStudentSaga({ payload }) {
  try {
    const { programId, topicId } = payload;

    const response = yield call(
      apis.getSingleTopicStudent,
      programId,
      topicId
    );

    yield put(
      getSingleTopicResponce({
        Topic: response.data.topic
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteSingleToicSaga({ payload }) {
  try {
    const { programId, topicId } = payload;

    const response = yield call(
      apis.deleteSingleTopic,
      programId,
      topicId
    );

    yield put(deleteSingleTopicResponce(topicId));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].topic.deleteMessage
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* addCustomerCommentSaga({ payload }) {
  try {
    const response = yield call(
      commentsApis.addCustomerComment,
      payload
    );

    yield put(
      getSingleTopicResponce({
        Topic: response.data.topic
      })
    );

    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].topic.addComment
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* addDirectorCommentSaga({ payload }) {
  try {
    const response = yield call(
      commentsApis.addDirectorComment,
      payload
    );

    yield put(
      getSingleTopicResponce({
        Topic: response.data.topic
      })
    );

    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].topic.addComment
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* deleteCustomerCommentSaga({ payload }) {
  try {
    const response = yield call(
      commentsApis.deleteCustomerComment,
      payload
    );
    yield put(
      getSingleTopicResponce({
        Topic: response.data.topic
      })
    );

    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].topic.addComment
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* deleteDirectorCommentSaga({ payload }) {
  try {
    const response = yield call(
      commentsApis.deleteDirectorComment,
      payload
    );

    yield put(
      getSingleTopicResponce({
        Topic: response.data.topic
      })
    );

    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].topic.addComment
      })
    );
  } catch (err) {
    console.error(err);
  }
}
