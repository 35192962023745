export const ADD_TOPIC_REQUEST = "ADD_TOPIC_REQUEST";
export const ADD_TOPIC_RESPONSE = "ADD_TOPIC_RESPONSE";
export const GET_CURRENT_PROGRAM_REQUEST =
  "GET_CURRENT_PROGRAM_REQUEST";
export const GET_CURRENT_PROGRAM_RESPONCE =
  "GET_CURRENT_PROGRAM_RESPONCE";
export const GET_ALL_TOPICS_REQUEST = "GET_ALL_TOPICS_REQUEST";
export const GET_CUSTOMER_TOPICS_REQUEST =
  "GET_CUSTOMER_TOPICS_REQUEST";
export const GET_ALL_TOPICS_RESPONCE = "GET_ALL_TOPICS_RESPONCE";
export const GET_SINGLE_TOPIC_REQUEST = "GET_SINGLE_TOPIC_REQUEST";
export const GET_SINGLE_TOPIC_STUDENT_REQUEST =
  "GET_SINGLE_TOPIC_STUDENT_REQUEST";
export const GET_SINGLE_TOPIC_RESPONCE = "GET_SINGLE_TOPIC_RESPONCE";
export const DELETE_SINGLE_TOPIC_REQUEST =
  "DELETE_SINGLE_TOPIC_REQUEST";
export const DELETE_SINGLE_TOPIC_RESPONCE =
  "DELETE_SINGLE_TOPIC_RESPONCE";
export const ADD_CUSTOMER_COMMENT = "ADD_CUSTOMER_COMMENT";
export const ADD_DIRECTOR_COMMENT = "ADD_DIRECTOR_COMMENT";
export const DELETE_CUSTOMER_COMMENT = "A DELETE_CUSTOMER_COMMENT";
export const DELETE_DIRECTOR_COMMENT = "A DELETE_DIRECTOR_COMMENT";
