import ForumIcon from "@mui/icons-material/Forum";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ButtonMui from "@mui/material/Button";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation
} from "react-router-dom/cjs/react-router-dom.min";
import ClickableBookCover from "../../../components/ClickableBookCover/ClickableBookCover";
import {
  getUserProgramAction,
  setUserProgramAction
} from "../../../store/Books/actions";
import { getCurrentProgramRequest } from "../../../store/Topics/actions";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import Footer from "../../Footer";
import styles from "./BooksListing.module.scss";

const ProgramBooksListing = ({ items, hasFooter = true }) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [selectedBook, setSelectedBook] = useState(null);
  const { pathname } = useLocation();
  const isProgram = pathname.includes("program");
  const history = useHistory();

  const programs = useSelector((state) => state.books.programs);
  // const userDataStr = localStorage.getItem("user");
  // const userData = userDataStr ? JSON.parse(userDataStr) : {};
  // const userId = userData?.id;
  const { login } = useSelector((state) => state.auth);
  const userId = login?.id;
  console.log(userId, "program", programs);

  const {
    program: { programName }
  } = messages;

  // students
  useEffect(() => {
    if (!isProgram) {
      if (userId) dispatch(getUserProgramAction({ userId }));
      else dispatch(setUserProgramAction({ programs: [] }));
    }
  }, [userId]);

  // director
  useEffect(() => {
    console.log("tany");
    if (isProgram) {
      if (userId) dispatch(getCurrentProgramRequest());
      else dispatch(setUserProgramAction({ programs: [] }));
    }
  }, [userId]);

  const currentProgram = useSelector(
    (state) => state.topics.currentProgram
  );
  useEffect(() => {
    if (currentProgram?.id)
      dispatch(setUserProgramAction({ programs: [currentProgram] }));
  }, [currentProgram?.id]);

  console.log("currentProgram", currentProgram);

  return programs.length ? (
    programs.map((program) => {
      return (
        <section key={program?.id} id="digital-books-section-id">
          <div className={styles["books-listing__container"]}>
            <Container>
              <div className="d-flex justify-content-between">
                <h3 style={{ paddingTop: "20px", width: "15rem" }}>
                  {programName}:{program.name}
                </h3>
                <div className="d-flex justify-content-between gap-2 align-items-baseline m-2">
                  {isProgram &&
                    program.program_director.id == userId && (
                      <ButtonMui
                        onClick={() =>
                          history.push(ROUTE_PATHS.StudentsList)
                        }
                        sx={{
                          color: "white",
                          backgroundImage:
                            "linear-gradient(to right,blue,rgba(66, 135, 245,0.5))"
                        }}
                      >
                        <PeopleAltIcon />
                        {"Students"}
                      </ButtonMui>
                    )}
                  <ButtonMui
                    onClick={() =>
                      isProgram
                        ? history.push(ROUTE_PATHS.Topics)
                        : history.push(
                            `${ROUTE_PATHS.StudentsTopics}/${program?.id}`
                          )
                    }
                    sx={{
                      color: "white",
                      backgroundColor: "rgb(255, 117, 237)"
                    }}
                  >
                    <ForumIcon />
                    {"Discussions"}
                  </ButtonMui>
                </div>
              </div>
              <Row xs="2" md="2" xl="5" lg="4" className="pt-1">
                {program.books?.map((book, index) => (
                  <Col className="mb-5" key={book.id}>
                    <div
                      className={`position-relative ${styles.minHieghtBookClickable}`}
                    >
                      <ClickableBookCover
                        isSelected={selectedBook === book.id}
                        book={book}
                        index={index}
                        clearSelection={() => setSelectedBook(null)}
                        selectBook={() => setSelectedBook(book.id)}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
          {hasFooter && (
            <>
              <Footer />
            </>
          )}
        </section>
      );
    })
  ) : (
    <></>
  );
};

ProgramBooksListing.propTypes = {
  items: PropTypes.number,
  onClick: PropTypes.func,
  direction: PropTypes.string,
  hasFooter: PropTypes.bool
};

ProgramBooksListing.defaultProps = {
  items: 10
};

export default ProgramBooksListing;
