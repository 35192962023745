import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CloseButtonIcon from "../../../../assets/svgs/close-button-icon";

import {
  getBookSingleTestRequest,
  answerBookSingleTestRequest,
  updateBookTestsStatus,
  removeUpdateBookTestsStatus
} from "../../../../store/Books/actions";
import Group from "../../../../assets/svgs/Group.svg";
import Button from "../../../../components/Button";
import "./Test.scss";
import { KEEP_LINE_SPACES } from "../../../../utils/Patterns";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export default function Test({
  testAllAnwsered,
  closeTestModal,
  bookTest,
  testType,
  setImageModal,
  markTestAsSolved,
  setSectionScorePopUp
}) {
  const { pathname } = useLocation();
  const isAuthor = pathname.includes("author");

  const { messages } = useIntl();
  const {
    test: {
      title,
      rightAnswer,
      success,
      failed,
      wrongAnswer,
      score,
      explanation,
      answeredTest,
      viewTest,
      notedescription,
      note,
      mark,
      sectionscore,
      advice,
      testReport
    },
    buttons: { submit, ok }
  } = messages;

  const dispatch = useDispatch();

  const bookSingleTest = useSelector(
    (state) => state.books.bookSingleTest
  );
  // const bookSingleTestCanceled = useSelector(
  //   (state) => state.books.bookSingleTestCanceled
  // );

  const [testTypeQuestion, setTestTypeQuestion] = useState("pre_");

  const [testQuestionAnwseredView, setTestQuestionAnwseredView] =
    useState(null);

  const [scoreView, setScoreView] = useState(false);

  useEffect(async () => {
    testType === 0
      ? setTestTypeQuestion("pre_")
      : setTestTypeQuestion("post_");

    testAllAnwsered
      ? setTestQuestionAnwseredView(true)
      : setTestQuestionAnwseredView(false);
    if (bookTest?.id) {
      await dispatch(
        getBookSingleTestRequest({ id: bookTest.id, isAuthor })
      );
    }
  }, [bookTest, testType, testAllAnwsered]);

  const getTotalScore = () => {
    const score = bookSingleTest
      ? bookSingleTest[`${testTypeQuestion}test_points`]
      : 0;
    return score ? score : 0;
  };

  const submitAnAnswer = async (questionId, answer) => {
    const payload = {
      test_question_id: questionId,
      test_answer_choice_id: answer,
      test_type: testType
    };
    try {
      await dispatch(answerBookSingleTestRequest(payload));
    } catch (err) {
      console.error(err);
    }
  };

  const setImageModalToBook = (img) => {
    setImageModal({ src: img.src, title: img.title });
  };

  const setSectionScorePopUpToBook = () => {
    setSectionScorePopUp();
  };
  return (
    <>
      <div
        className={
          scoreView &&
          bookSingleTest?.sections_results > 0 &&
          testType === 1
            ? "relative bg-modal-image"
            : "relative"
        }
      >
        <div
          onClick={() => {
            closeTestModal();
          }}
          className="cursor-pointer d-flex justify-content-end"
        >
          <CloseButtonIcon />
        </div>
        {testQuestionAnwseredView ? (
          <>
            <Typography
              id="modal-modal-title"
              variant="h5"
              className="text-center text-primary mb-4"
            >
              {bookSingleTest?.title} {title} {answeredTest}
            </Typography>
            <>
              <div>
                {50 <
                (getTotalScore() / bookSingleTest?.total_points) *
                  100 ? (
                  <p className="success text-center my-4">
                    {success}
                  </p>
                ) : (
                  <p className="error text-center my-4">{failed} </p>
                )}
                <div className="precentage">
                  <CircularProgressbar
                    value={Math.round(
                      (getTotalScore() /
                        bookSingleTest?.total_points) *
                        100
                    )}
                    text={`${Math.round(
                      (getTotalScore() /
                        bookSingleTest?.total_points) *
                        100
                    )}%`}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center flex-column align-items-center">
                <div className=" my-3">
                  <Button
                    text={viewTest}
                    className="continue_btn"
                    onClick={() => {
                      dispatch(
                        updateBookTestsStatus({
                          id: bookSingleTest.id,
                          type: testType
                        })
                      );

                      setTestQuestionAnwseredView(false);
                    }}
                  />
                </div>
                <div className=" my-3">
                  <Button
                    text={ok}
                    onClick={() => {
                      closeTestModal();
                      // if (testType === 1)
                      //   dispatch(
                      //     updateBookTestsStatus({
                      //       id: bookSingleTest.id,
                      //       type: testType
                      //     })
                      //   );
                    }}
                  />
                </div>
              </div>
            </>
          </>
        ) : (
          <>
            {scoreView ? (
              <>
                <>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    className="text-center text-primary mb-4"
                  >
                    {bookSingleTest?.title} {title}
                  </Typography>
                  <>
                    <div>
                      {50 <
                      (getTotalScore() /
                        bookSingleTest?.total_points) *
                        100 ? (
                        <p className="success text-center my-4">
                          {success}
                        </p>
                      ) : (
                        <p className="error text-center my-4">
                          {failed}{" "}
                        </p>
                      )}
                      <div className="precentage mb-4">
                        <CircularProgressbar
                          value={Math.round(
                            (getTotalScore() /
                              bookSingleTest?.total_points) *
                              100
                          )}
                          text={`${Math.round(
                            (getTotalScore() /
                              bookSingleTest?.total_points) *
                              100
                          )}%`}
                        />
                      </div>
                    </div>
                  </>

                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <div className=" my-4">
                      <Button
                        text={ok}
                        className="continue_btn"
                        onClick={() => {
                          if (testType === 1)
                            dispatch(
                              updateBookTestsStatus({
                                id: bookSingleTest.id,
                                type: testType
                              })
                            );
                          closeTestModal(testType);
                        }}
                      />
                    </div>
                  </div>

                  {bookSingleTest?.sections_results.length > 0 &&
                    testType === 1 && (
                      <>
                        <Typography
                          variant="p"
                          className="text-gray my-4 d-block"
                        >
                          {testReport}
                        </Typography>{" "}
                        <div className="mt-2 table-container table-results">
                          <table>
                            <tr>
                              <th>{mark}</th>
                              <th>{sectionscore}</th>
                              <th>{advice}</th>
                            </tr>

                            {bookSingleTest?.sections_results?.map(
                              (row, i) => (
                                <>
                                  <tr key={i}>
                                    <td> {row.name}</td>

                                    <td> {row.percentage}</td>
                                    <td> {row.message}</td>
                                  </tr>
                                </>
                              )
                            )}
                          </table>
                        </div>{" "}
                      </>
                    )}
                </>
              </>
            ) : (
              <>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  className="text-center text-primary"
                >
                  {bookSingleTest?.title} {title}
                </Typography>
                {testType === 1 && (
                  <div className="text-secondary my-3">
                    {score}: {getTotalScore()}/
                    {bookSingleTest?.total_points}
                  </div>
                )}
                {bookSingleTest?.test_questions?.find(
                  (qs) =>
                    !!qs?.image_url ||
                    qs?.test_answer_choices?.some(
                      (ans) => ans.image_url
                    )
                ) && (
                  <div className="note gray mb-3">
                    <span className="grey bold">{note} </span>
                    {notedescription}
                  </div>
                )}
                <div className="note text-primary mb-3">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: bookSingleTest?.note?.replace(
                        KEEP_LINE_SPACES,
                        "<br />"
                      )
                    }}
                  ></span>
                </div>
                {bookSingleTest && (
                  <>
                    {bookSingleTest?.test_questions?.map(
                      (question, questionId) => (
                        <div className="mb-2" key={question.id}>
                          <div className=" mb-2">
                            <div className="d-flex align-items-baseline">
                              <h4 className="me-2 mb-0">
                                Q{questionId + 1}-
                              </h4>

                              <h4 className="mb-0 ">
                                {question.question}
                              </h4>
                            </div>

                            {question.image_url && (
                              <div
                                className="d-flex container_image_question mb-2"
                                onClick={() =>
                                  setImageModalToBook({
                                    src: question.image_url,
                                    title: `Q${questionId + 1}-${
                                      question.question
                                    }`
                                  })
                                }
                              >
                                <img
                                  src={question.image_url}
                                  className="img-thumbnail question-image"
                                />
                                <div className="middle">
                                  <div className="text">
                                    <img
                                      height="20px"
                                      src={Group}
                                      alt="medblend-image"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="d-flex justify-content-center flex-column ">
                            <ol type="A">
                              {question?.test_answer_choices?.map(
                                (
                                  {
                                    answer,
                                    id: answerId,
                                    image_url,
                                    correct
                                  },
                                  index
                                ) => (
                                  <li key={index}>
                                    <div className="d-flex align-items-center">
                                      <div className="mb-2 answer">
                                        <label
                                          key={answerId}
                                          className="cursor-pointer d-flex align-items-start"
                                          htmlFor={
                                            !image_url
                                              ? `${question.id}_${answerId}`
                                              : ""
                                          }
                                        >
                                          <input
                                            id={`${question.id}_${answerId}`}
                                            type="radio"
                                            value={answer}
                                            disabled={
                                              question[
                                                `${testTypeQuestion}test_user_answer`
                                              ] || isAuthor
                                            }
                                            className="mt-2 cursor-pointer mr-11"
                                            name={`question_${questionId}`}
                                            checked={
                                              question[
                                                `${testTypeQuestion}test_user_answer`
                                              ]
                                                ?.test_answer_choice_id ===
                                              answerId
                                            }
                                            onChange={() => {
                                              submitAnAnswer(
                                                question.id,
                                                answerId
                                              );
                                              if (
                                                testType === 0 &&
                                                !bookSingleTest.is_mandatory
                                              )
                                                dispatch(
                                                  updateBookTestsStatus(
                                                    {
                                                      id: bookSingleTest.id,
                                                      type: testType
                                                    }
                                                  )
                                                );
                                            }}
                                          />
                                          {image_url && (
                                            <div
                                              className="container_image_answer"
                                              onClick={() =>
                                                setImageModalToBook({
                                                  src: image_url,
                                                  title: `${
                                                    index + 1
                                                  }-${answer}`
                                                })
                                              }
                                            >
                                              <img
                                                src={image_url}
                                                width="100px"
                                                className="img-thumbnail answer-image"
                                                alt="answer"
                                              />
                                              <div className="middle">
                                                <div className="text">
                                                  <img
                                                    height="20px"
                                                    src={Group}
                                                    alt="medblend-image"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          <span className="answer-text">
                                            {answer}
                                          </span>
                                        </label>
                                      </div>
                                      <div className="result">
                                        {question[
                                          `${testTypeQuestion}test_user_answer`
                                        ] && (
                                          <>
                                            {question[
                                              `${testTypeQuestion}test_user_answer`
                                            ]?.correct &&
                                              question[
                                                `${testTypeQuestion}test_user_answer`
                                              ]
                                                ?.test_answer_choice_id ===
                                                answerId &&
                                              testType === 1 && (
                                                <div className="success">
                                                  {rightAnswer}
                                                </div>
                                              )}
                                            {question[
                                              `${testTypeQuestion}test_user_answer`
                                            ]
                                              ?.test_answer_choice_id ===
                                              answerId &&
                                            question[
                                              `${testTypeQuestion}test_user_answer`
                                            ]?.correct === false &&
                                            testType === 1 ? (
                                              <div className="error">
                                                {wrongAnswer}
                                              </div>
                                            ) : (
                                              question[
                                                `${testTypeQuestion}test_user_answer`
                                              ]?.correct === false &&
                                              correct &&
                                              testType === 1 && (
                                                <div className="success">
                                                  {rightAnswer}
                                                </div>
                                              )
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                )
                              )}
                            </ol>
                            {!!question[
                              `${testTypeQuestion}test_user_answer`
                            ] &&
                              !!question?.justification &&
                              testType === 1 && (
                                <div className="explanation my-3">
                                  {explanation} :
                                  {question?.justification}
                                  {question?.justification_image_url && (
                                    <div style={{ width: "30vw" }}>
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "100%"
                                        }}
                                        src={
                                          question?.justification_image_url
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                      )
                    )}

                    {bookSingleTest?.test_questions.every(
                      (question) =>
                        question?.[
                          `${testTypeQuestion}test_user_answer`
                        ]
                    ) && (
                      <div className="button-submit">
                        <Button
                          text={submit}
                          onClick={() => {
                            markTestAsSolved(bookSingleTest.id);
                            if (testType === 0) {
                              dispatch(
                                removeUpdateBookTestsStatus({
                                  id: bookTest?.id,
                                  type: 0
                                })
                              );
                              setSectionScorePopUpToBook();
                            }
                            if (testType === 1)
                              dispatch(
                                updateBookTestsStatus({
                                  id: bookSingleTest.id,
                                  type: testType
                                })
                              );

                            if (testAllAnwsered) {
                              closeTestModal(testType);
                            }
                            if (!testAllAnwsered) {
                              setScoreView(true);
                            }
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

Test.propTypes = {
  closeTestModal: PropTypes.func,
  markTestAsSolved: PropTypes.func,
  bookTest: PropTypes.object,
  testAllAnwsered: PropTypes.boolean,
  testType: PropTypes.number,
  setImageModal: PropTypes.func,
  setSectionScorePopUp: PropTypes.func
};
