import { axiosInstance, customer_portal } from ".";
const getCustromerFinalTest = async (book_id) =>
  await axiosInstance.get(
    `${customer_portal}/final_tests?book_id=${book_id}`
  );

const setNewAnswerCustromerFinalTest = async (book_id, answers) => {
  console.log("arrayOfAnswers :::?", answers);

  const response = await axiosInstance.post(
    `${customer_portal}/final_tests/submit_final_test?book_id=${book_id}`,
    { answers }
  );

  return response;
};

export { getCustromerFinalTest, setNewAnswerCustromerFinalTest };
