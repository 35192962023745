import * as types from "./types";

export const setTopicRequest = (programId, topic) => ({
  type: types.ADD_TOPIC_REQUEST,
  payload: { programId, topic }
});

export const setTopicResponse = () => ({
  type: types.ADD_TOPIC_RESPONSE
});

export const getCurrentProgramRequest = () => ({
  type: types.GET_CURRENT_PROGRAM_REQUEST
});

export const getCurrentProgramResponce = (data) => ({
  type: types.GET_CURRENT_PROGRAM_RESPONCE,
  payload: data
});

export const getAllTopicsRequest = (programId) => ({
  type: types.GET_ALL_TOPICS_REQUEST,
  payload: { programId }
});

export const getAllTopicsResponce = (data) => ({
  type: types.GET_ALL_TOPICS_RESPONCE,
  payload: { data }
});
export const getCustomerTopicsRequest = (programId) => ({
  type: types.GET_CUSTOMER_TOPICS_REQUEST,
  payload: { programId }
});

export const getSingleTopicRequest = (programId, topicId) => ({
  type: types.GET_SINGLE_TOPIC_REQUEST,
  payload: { programId, topicId }
});
export const getSingleTopicStudentRequest = (programId, topicId) => ({
  type: types.GET_SINGLE_TOPIC_STUDENT_REQUEST,
  payload: { programId, topicId }
});

export const getSingleTopicResponce = (data) => ({
  type: types.GET_SINGLE_TOPIC_RESPONCE,
  payload: data
});

export const deleteSingleTopicRequest = (programId, topicId) => ({
  type: types.DELETE_SINGLE_TOPIC_REQUEST,
  payload: { programId, topicId }
});

export const deleteSingleTopicResponce = (topicId) => ({
  type: types.DELETE_SINGLE_TOPIC_RESPONCE,
  payload: { topicId }
});

export const addCustomerCommentRequest = (data) => ({
  type: types.ADD_CUSTOMER_COMMENT,
  payload: data
});

export const addDirectorCommentRequest = (data) => ({
  type: types.ADD_DIRECTOR_COMMENT,
  payload: data
});
export const deleteCustomerCommentRequest = (data) => ({
  type: types.DELETE_CUSTOMER_COMMENT,
  payload: data
});

export const deleteDirectorCommentRequest = (data) => ({
  type: types.DELETE_DIRECTOR_COMMENT,
  payload: data
});
