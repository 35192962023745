import React, { useEffect, useState } from "react";
// import propTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFinalTestRequest,
  setNewFinalTestAnswerRequest
} from "../../store/FinalTest/actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import BackButton from "../../components/BackButton";
import { Typography } from "@mui/material";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Group from "../../assets/svgs/Group.svg";
import Button from "../../components/Button";
import { showHideSnackbar } from "../../store/Snackbar/actions";
import { useIntl } from "react-intl";
// import { answersSchema } from "./validationAnswer";
function FinalTest() {
  const { book_id, mode } = useParams();

  const { messages } = useIntl();
  const {
    finalTest: { allQuestionRequired }
  } = messages;
  const dispatch = useDispatch();
  const history = useHistory();

  const [ImageModalTest, setImageModalTest] = useState(null);
  const [allTestAnswers, setAllTestAnswers] = useState([]);

  const collectAnswers = async (questionId, answer) => {
    const questionExist = allTestAnswers.find(
      (ans) => ans.question_id === questionId
    );

    let payload = {
      question_id: questionId,
      answer_id: answer
    };

    if (questionExist) {
      setAllTestAnswers((oldAnswers) =>
        oldAnswers.map((ans) =>
          ans.question_id === questionId
            ? { ...ans, answer_id: answer }
            : ans
        )
      );
    } else {
      setAllTestAnswers((oldAnswers) => [...oldAnswers, payload]);
    }
  };

  const finalTest = useSelector(
    (state) => state.FinalTestReducer.finalTest
  );

  useEffect(() => {
    dispatch(getFinalTestRequest({ book_id }));
  }, []);
  const submitAnswers = async () => {
    // const isValid = await answersSchema.isValid(allTestAnswers);
    // console.log("Validation result:", isValid);

    if (
      allTestAnswers.length === finalTest?.details?.questions?.length
    ) {
      dispatch(
        setNewFinalTestAnswerRequest({
          book_id: book_id,
          answers: allTestAnswers
        })
      );

      history.push(`${ROUTE_PATHS.FinalTestScore}/${book_id}`);
    } else {
      dispatch(
        showHideSnackbar({
          isOpen: true,
          type: "error",
          message: allQuestionRequired
        })
      );
    }
  };

  const handleTestButtonClick = () => {
    const route = `${ROUTE_PATHS["bookAllTests"]}/${book_id}`;
    history.push(route);
  };
  const isView = mode === "View";
  return (
    <>
      <div className="mx-auto w-80 relative">
        <div className="my-auto d-flex">
          <BackButton
            className="my-auto me-4 backButton"
            icon={"b"}
            otherWise={handleTestButtonClick}
          />
          <span className="headerText">Course Tests</span>
        </div>
        <Typography
          id="modal-modal-title"
          variant="h5"
          className="text-center text-primary mb-4"
        >
          {finalTest.book_title} Final Test
        </Typography>

        <div className="note gray mb-3">
          <span className="grey bold"> Note : </span>
          {finalTest.note}
        </div>
        {ImageModalTest && (
          <Lightbox
            mainSrc={ImageModalTest}
            onCloseRequest={() => setImageModalTest(null)}
          />
        )}
        {finalTest?.details?.questions.map((question, questionId) => (
          <div className="mb-2" key={questionId}>
            <div className=" mb-2">
              <div className="d-flex align-items-baseline">
                <p className="me-2 bold mb-0">Q{questionId + 1}-</p>
                <h4 className="mb-0 ">{question.question} ?</h4>
              </div>

              {question.image_url && (
                <div
                  className="d-flex container_image_question mb-2"
                  onClick={() =>
                    setImageModalTest(question.image_url)
                  }
                >
                  <img
                    src={question.image_url}
                    className="img-thumbnail question-image"
                  />
                  <div className="middle">
                    <div className="text">
                      <img
                        height="20px"
                        src={Group}
                        alt="medblend-image"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center flex-column ">
              {question?.answer_choices?.map(
                (
                  { answer, answer_choice_id, image_url, correct },
                  index
                ) => {
                  const userAnswer =
                    finalTest?.user_answers?.answers?.find(
                      (answer) =>
                        answer.answer_id === answer_choice_id
                    );

                  return (
                    <div
                      key={index}
                      className="d-flex align-items-center"
                    >
                      <div className="mb-2 answer">
                        <label
                          key={answer_choice_id}
                          className="cursor-pointer d-flex align-items-start"
                          htmlFor={
                            !image_url
                              ? `${question.question_id}_${answer_choice_id}`
                              : ""
                          }
                        >
                          <input
                            id={`${question.question_id}_${answer_choice_id}`}
                            type="radio"
                            value={answer}
                            disabled={isView ? true : false}
                            className="mt-2 cursor-pointer mr-11"
                            name={`question_${questionId}`}
                            checked={
                              isView
                                ? userAnswer?.answer_id ===
                                    answer_choice_id &&
                                  userAnswer.correct === correct
                                : undefined
                            }
                            onChange={() => {
                              collectAnswers(
                                question.question_id,
                                answer_choice_id
                              );
                            }}
                          />
                          {image_url && (
                            <div
                              className="container_image_answer"
                              onClick={() =>
                                setImageModalTest(image_url)
                              }
                            >
                              <img
                                src={image_url}
                                width="100px"
                                className="img-thumbnail answer-image"
                                alt="answer"
                              />
                              <div className="middle">
                                <div className="text">
                                  <img
                                    height="20px"
                                    src={Group}
                                    alt="medblend-image"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <span className="answer-text">
                            {answer}
                          </span>
                        </label>
                      </div>
                    </div>
                  );
                }
              )}
              {isView &&
                !!finalTest?.user_answers.answers.length &&
                question?.justification && (
                  <div className="explanation my-3">
                    <p>
                      Explanation :{` ${question?.justification}`}
                    </p>
                    {question.justification_image_url && (
                      <div style={{ width: "30vw" }}>
                        <img
                          style={{
                            width: "100%",
                            height: "100%"
                          }}
                          src={question.justification_image_url}
                        />
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        ))}
        <div className={`d-flex justify-content-end mt-2`}>
          <Button
            className={isView ? "disaleSubmit" : "primary"}
            disabled={isView ? true : false}
            onClick={submitAnswers}
            type="button"
            text="Submit"
          />
        </div>
      </div>
    </>
  );
}

export default FinalTest;
