import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import ProgressTable from "./ProgressTable";
import { useParams } from "react-router-dom";
import propTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getStudentsListRequest } from "../../store/StudentsList/action";
import imgTest from "../../assets/images/Generic avatar.png";
import StudentsOrTopics from "../../components/StudentsOrTopics";

function StudentProgress() {
  const { studentId } = useParams();
  const dispatch = useDispatch();

  const arr = useSelector((state) => state.studentslist.resList);
  useEffect(() => {
    dispatch(getStudentsListRequest());
  }, [dispatch]);

  console.log("filter students overview", arr);
  const currentStudent = arr.find((row) => {
    return row.user.id == studentId;
  });

  return (
    <>
      <Container maxWidth="xl">
        <StudentsOrTopics />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "5%"
          }}
        >
          <Box
            sx={{
              p: 1,
              width: "80%",
              backgroundColor: "#F8F8F9",
              borderRadius: 2
            }}
          >
            <div className="student d-flex ">
              <img
                src={
                  currentStudent?.user?.image
                    ? currentStudent.user.image
                    : imgTest
                }
                className="me-1 rounded-circle"
                width={30}
                height={30}
                alt="student image"
              />
              <h4 className="fw-bold align-self-end">
                {currentStudent?.user?.first_name +
                  " " +
                  currentStudent?.user?.second_name}
              </h4>
            </div>
          </Box>
        </Box>
        <ProgressTable books={currentStudent?.books} />
      </Container>
    </>
  );
}
StudentProgress.propTypes = {
  location: propTypes.object.isRequired
};
export default StudentProgress;
